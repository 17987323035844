import React from 'react';
import Login from 'gatsby-theme-q3/src/pages/login';
import FormContainer from '../../components/FormContainer';
import SEOHelmet from '../../components/SEOHelmet';
import HydrationFix from '../../components/HydrationFix';

const CustomLogin = (props) => (
  <HydrationFix>
    <FormContainer>
      <SEOHelmet title="Login" />
      <Login {...props} />
    </FormContainer>
  </HydrationFix>
);

export default CustomLogin;
