import React from 'react';
import AccountPublicGateway from 'gatsby-theme-q3/src/components/AccountPublicGateway';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

export default ({ children }) => (
  <AccountPublicGateway>
    <Container maxWidth="sm">
      <Box my={4}>
        <Paper>
          <Box p={3}>{children}</Box>
        </Paper>
      </Box>
    </Container>
  </AccountPublicGateway>
);
